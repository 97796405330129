import React, {useState} from 'react';

import Container from 'common/components/UI/ContainerTwo';
import CheckBox from 'common/components/Checkbox';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import Image from 'common/components/Image';
import Text from 'common/components/Text';

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from './subscribe.style';

import bg1 from 'common/assets/image/agencyModern/cta/1.png';
// import bg2 from 'common/assets/image/agencyModern/cta/books.png';
import books2 from 'common/assets/image/agencyModern/cta/books2.png';
import bg3 from 'common/assets/image/agencyModern/cta/3.png';
import bg4 from 'common/assets/image/agencyModern/cta/4.png';
import cactus from 'common/assets/image/agencyModern/cta/cactus.png';
import {ImageWrapper} from "../Services/service.style";
import coffeedesk from 'common/assets/image/agencyModern/coffeedesk.png';

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    const response = await fetch('https://buttondown.email/api/emails/embed-subscribe/cozyintellect', requestOptions);
    if (response.ok) {
      setSubmitted(true);
      setFormError('')
    }
    else {
      console.error(response.status, response.statusText)
      if (response.status === 400) {
        setFormError('Invalid email address. Please check your email address and try again.')
      }
      else {
        setFormError('Unknown error. Please try back later.')
      }
    }
  };

  return (
    <SectionWrapper id="subscribe">
      <Container>
        <FooterInner>
          <Content>
            <Heading as="h3" content="Stay in the loop" />
            <ImageWrapper className="highlight__item">
              <Image
                src={coffeedesk}
                alt={`Desk with Coffee`}
                objectFit="cover"
                className="highlight__image"
              />
            </ImageWrapper>
            <Text content="Subscribe to our newsletter to stay ahead with the latest AI trends, receive exclusive insights, and discover how our custom solutions can revolutionize your business." />
          </Content>
          {(submitted ?
              <SubscriptionForm>
                Subscription successful. You will now receive our newsletter at {email}.
              </SubscriptionForm>
              :
              <div>
                <form onSubmit={handleSubmit}>
                  <SubscriptionForm>
                    <div>
                    <Input
                      inputType="email"
                      placeholder="Enter Email Address"
                      iconPosition="left"
                      aria-label="email"
                      name="email"
                      id="bd-email"
                      value={email}
                      onChange={setEmail}
                    />
                    <Button title="Subscribe" value="Subscribe" type="submit" />
                    </div>
                  </SubscriptionForm>
                </form>
              </div>
          )}
        </FooterInner>
      </Container>
      <Image src={bg1} alt="bg1" className="illustration bg1" />
      <Image src={books2} alt="books2" className="illustration bg2" />
      <Image src={bg3} alt="bg3" className="illustration bg3" />
      <Image src={bg4} alt="bg4" className="illustration bg4" />
      <Image src={cactus} alt="cactus" className="illustration bg5" />
    </SectionWrapper>
  );
};

export default Subscribe;
