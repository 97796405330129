import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Container from 'common/components/UI/ContainerTwo';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Link from 'common/components/Link';
import Text from 'common/components/Text';

import FooterWrapper, {
  FooterInner,
  CopyrightInfo,
  FooterWidget,
  Nav,
} from './footer.style';

import logo from 'common/assets/image/agencyModern/logo.png';

const Footer = () => {
  const Data = useStaticQuery(graphql`
    query {
      agencyModernJson {
        aboutUs {
          id
          title
        }
        ourInformation {
          id
          title
        }
        myAccount {
          id
          title
        }
        social {
          id
          title
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container>
        <FooterInner>
          <CopyrightInfo>
            <Fade up delay={100}>
              <Image src={logo} alt="Logo" />
              <p>
                <Link href="#">Terms of use</Link> |{' '}
                <Link href="#">Privacy</Link>
              </p>
              <Text
                className="copyright"
                content="Copyright 2023 CozyIntellect Inc."
              />
            </Fade>
          </CopyrightInfo>

          {/*<FooterWidget>*/}
          {/*  <Fade up delay={200}>*/}
          {/*    <Heading as="h4" content="About Us" />*/}
          {/*    <Nav>*/}
          {/*      {Data.agencyModernJson.aboutUs.map((item) => (*/}
          {/*        <Link key={item.id} href="#">*/}
          {/*          {item.title}*/}
          {/*        </Link>*/}
          {/*      ))}*/}
          {/*    </Nav>*/}
          {/*  </Fade>*/}
          {/*</FooterWidget>*/}

          {/*<FooterWidget>*/}
          {/*  <Fade up delay={300}>*/}
          {/*    <Heading as="h4" content="Our Information" />*/}
          {/*    <Nav>*/}
          {/*      {Data.agencyModernJson.ourInformation.map((item) => (*/}
          {/*        <Link key={item.id} href="#">*/}
          {/*          {item.title}*/}
          {/*        </Link>*/}
          {/*      ))}*/}
          {/*    </Nav>*/}
          {/*  </Fade>*/}
          {/*</FooterWidget>*/}

          {/*<FooterWidget>*/}
          {/*  <Fade up delay={400}>*/}
          {/*    <Heading as="h4" content="My Account" />*/}
          {/*    <Nav>*/}
          {/*      {Data.agencyModernJson.myAccount.map((item) => (*/}
          {/*        <Link key={item.id} href="#">*/}
          {/*          {item.title}*/}
          {/*        </Link>*/}
          {/*      ))}*/}
          {/*    </Nav>*/}
          {/*  </Fade>*/}
          {/*</FooterWidget>*/}

          {/*<FooterWidget>*/}
          {/*  <Fade up delay={500}>*/}
          {/*    <Heading as="h4" content="Connect" />*/}
          {/*    <Nav>*/}
          {/*      {Data.agencyModernJson.social.map((item) => (*/}
          {/*        <Link key={item.id} href="#">*/}
          {/*          <Image src={item.icon.publicURL} alt="Facebook" />*/}
          {/*          {item.title}*/}
          {/*        </Link>*/}
          {/*      ))}*/}
          {/*    </Nav>*/}
          {/*  </Fade>*/}
          {/*</FooterWidget>*/}
        </FooterInner>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
