import React, {useState}  from 'react';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Input from 'common/components/Input';
import Container from 'common/components/UI/ContainerTwo';
import BannerWrapper, {
  BannerContent,
  Subscribe,
  SponsoredBy,
  ImageGroup,
} from './banner.style';

import paypal from 'common/assets/image/agencyModern/paypal.png';
// import stripe from 'common/assets/image/agencyModern/stripe.png';
import aws from 'common/assets/image/agencyModern/aws.png';
import google from 'common/assets/image/agencyModern/google.png';
import dropbox from 'common/assets/image/agencyModern/dropbox.png';

const Banner = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    const response = await fetch('https://buttondown.email/api/emails/embed-subscribe/cozyintellect', requestOptions);
    if (response.ok) {
      setSubmitted(true);
      setFormError('')
    }
    else {
      console.error(response.status, response.statusText)
      if (response.status === 400) {
        setFormError('Invalid email address. Please check your email address and try again.')
      }
      else {
        setFormError('Unknown error. Please try back later.')
      }
    }
  };

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Heading
            as="h1"
            content="Delight your customers with immersive AI experiences"
          />
          <Text
            className="banner-caption"
            content="Welcome to CozyIntellect, where cutting-edge technology meets comfort and personalization. We're a boutique AI development agency based in New York City, specializing in creating bespoke, innovative, and impactful AI solutions for businesses of all sizes. Our mission? To help you deliver transformative digital experiences that captivate your customers and redefine what's possible for your brand."
          />
          {(submitted ?
            <Subscribe>
              Subscription successful. You will now receive our newsletter at {email}.
            </Subscribe>
              :
              <div>
              <form onSubmit={handleSubmit}>
                <Subscribe>
                  <Input
                    inputType="email"
                    placeholder="Enter Email Address"
                    iconPosition="left"
                    aria-label="email"
                    name="email"
                    id="bd-email"
                    value={email}
                    onChange={setEmail}
                  />
                  <Button title="Subscribe" value="Subscribe" type="submit" />
                </Subscribe>
              </form>
              </div>
          )}

          <SponsoredBy>
            <Text className="sponsoredBy" content="Powered by:" />
            <ImageGroup>
              {/*<Image src={paypal} alt="PayPal" />*/}
              <Image src={google} alt="Google" />
              <Image src={dropbox} alt="Dropbox" />
              <Image style={{paddingTop: '14px'}} src={aws} alt="AWS" />
            </ImageGroup>
          </SponsoredBy>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;