import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';

import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/ContainerTwo';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Link from 'common/components/Link';
import Heading from 'common/components/Heading';
import SectionWrapper, {
  SectionTitle,
  FeatureWrapper,
} from './ultimateFeature.style';

const UltimateFeature = () => {
  const Data = useStaticQuery(graphql`
    query {
      agencyModernJson {
        ultimateFeatures {
          id
          title
          desc
          learnMoreLink
          icon {
            publicURL
          }
        }
      }
    }
  `);
  const features = Data.agencyModernJson.ultimateFeatures;
  return (
    <SectionWrapper id="features">
      <Container>
        <SectionTitle>
          <Heading content="Unleashing the Power of AI with CozyIntellect: Our Key Features" />
          <Text content="Our suite of comprehensive services is meticulously designed to cater to your unique business needs, ushering in growth, efficiency, and an edge in the competitive market. Discover the key features that make us your perfect partner in this digital era." />
        </SectionTitle>

        <FeatureWrapper>
          {features.map((feature, index) => (
            <FeatureBlock
              key={index}
              icon={
                <Image
                  src={feature.icon.publicURL}
                  alt={`Feature Image ${feature.id}`}
                  className="service__image"
                />
              }
              title={<Heading as="h4" content={feature.title} />}
              description={
                <React.Fragment>
                  <Text content={feature.desc} />
                  {feature.learnMoreLink ? <Link
                    href={feature.learnMoreLink ?? feature.learnMoreLink}
                    className="learn__more"
                  >
                    Learn More <Icon icon={chevronRight} />
                  </Link> : <div/>}
                </React.Fragment>
              }
              className="ultimateFeature"
            />
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

UltimateFeature.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

UltimateFeature.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-25px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-25px', '-45px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
};

export default UltimateFeature;
